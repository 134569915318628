import type { InputRef } from 'antd';
import { message } from 'antd';
import type { ValueAndLabelData, BaseData } from 'egenie-utils';
import { printWayBill, printHelper } from 'egenie-common';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import React from 'react';
import { getCourierEnabled, playVoice, voiceData } from '../../utils';
import type { Operation } from './types';

export class Store {
  constructor() {
    getCourierEnabled()
      .then((expressList) => this.expressList = expressList);
  }

  @observable public expressList: ValueAndLabelData = [];

  @observable public operationResult: Operation = {};

  @observable public printChecked = false;

  @observable public selectExpress = undefined;

  @observable public expressNo = '';

  @observable.ref public expressNoRef = React.createRef<InputRef>();

  // 选择快递
  @action public handleExpressChange = (value: string) => {
    this.selectExpress = value;
  };

  // 选择是否立即打印
  @action public handlePrintCheckedChange = (e) => {
    this.printChecked = e.target.checked;
  };

  // 立即打印检查
  @action public queryTrackingNumber = (e): void => {
    const { value } = e.target;
    if (!value) {
      playVoice(voiceData['请输入快递单号或唯一码']);
      message.warning({
        key: '请扫描快递单号或唯一码',
        content: '请扫描快递单号或唯一码',
      });
      return;
    }

    if (!this.printChecked) {
      message.warning({
        key: '请勾选扫描成功后直接打印',
        content: '请勾选扫描成功后直接打印',
      });
      return;
    }

    if (this.selectExpress) {
      this.handlePrintRequest('UPDATE');
    } else {
      this.handlePrintRequest('DEFALUT');
    }
  };

  @action public handleExpressNoChange = (e) => {
    this.expressNo = e.target.value;
  };

  // 手动打印检查
  @action public handlePrintCheck = (type: string): void => {
    if (!this.expressNo) {
      this.expressNoRef.current?.focus();
      message.error('请扫描快递单号');
      return;
    }

    if (type === 'UPDATE') {
      if (!this.selectExpress) {
        message.error('请选择转发的快递公司');
        return null;
      }
    }

    this.handlePrintRequest(type);
  };

  // 执行扫描
  @action private handlePrintRequest = async(type: string) => {
    const courierNo = this.expressNo;
    this.expressNo = '';
    this.operationResult = {};
    this.expressNoRef.current?.focus();

    const info = await request<BaseData<Operation>>({
      method: 'post',
      url: '/api/cloud/wms/rest/order/v2/courierForward',
      data: {
        courierNo,
        courierId: type === 'UPDATE' ? this.selectExpress : null,
      },
    });

    this.operationResult = info.data || {};
    await printWayBill.getDataAndPrint({
      printSrc: '8', // 补打快递
      userDataIds: `${info.data?.wmsOrderId}`, // 发货单id
      tempType: 0,
      printType: '0', // 打印类型
      preview: false,
    });
  };

  @action public handlePrintStockOutOrder = async() => {
    const waybillCode = this.expressNo;
    this.expressNo = '';
    this.expressNoRef.current?.focus();

    if (waybillCode) {
      const info = await request<BaseData<{ dataList: any[]; tempData: any; }>>({
        url: '/api/cloud/wms/rest/packAndDispatch/rePrint',
        params: { waybillCode },
      });

      printHelper.toggleToRookie();
      await printHelper.print({
        preview: false,
        templateData: info.data?.tempData,
        contents: info.data?.dataList,
      });
    }
  };

  @action public handlePrintDeliver = async() => {
    const courierNo = this.expressNo;
    this.expressNo = '';
    this.expressNoRef.current?.focus();

    if (courierNo) {
      const wmsOrderData = await request<BaseData<Operation>>({
        method: 'post',
        url: '/api/cloud/wms/rest/order/v2/courierForward',
        data: { courierNo },
      });

      const printData = await request<BaseData<{ tempData: any; userDataList: any[]; }>>({ url: `/api/cloud/print/wms/delivery/print/wms/order/${wmsOrderData?.data?.wmsOrderId}` });

      printHelper.toggleToLodop();
      await printHelper.print({
        preview: false,
        templateData: printData.data?.tempData,
        contents: printData.data?.userDataList,
      });
    }
  };

  @action public handlePrintBox = async() => {
    const wmsOrderBoxNo = this.expressNo;
    this.expressNo = '';
    this.expressNoRef.current?.focus();
    if (wmsOrderBoxNo) {
      const printData = await request<BaseData<{ tempData: any; userDataList: any[]; }>>({
        url: '/api/cloud/wms/rest/box/print',
        data: {
          wmsOrderBoxNo,
          printType: 1,
        },
        method: 'POST',
      });
      printHelper.toggleToRookieCustom();
      await printHelper.print({
        preview: false,
        templateData: printData.data?.tempData,
        contents: printData.data?.userDataList,
      });
    }
  };
}
