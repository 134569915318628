import { InfoCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Input, Tooltip, Checkbox, Select, Button, Space, Timeline, message } from 'antd';
import { Permission } from 'egenie-utils';
import { Provider, inject, observer } from 'mobx-react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './index.less';
import { Store } from './store';

const store = new Store();

@inject('store')
@observer
class Left extends React.Component<{ store?: Store; }> {
  render() {
    const {
      queryTrackingNumber,
      expressList,
      expressNo,
      handleExpressNoChange,
      handlePrintCheck,
      handleExpressChange,
      selectExpress,
      printChecked,
      handlePrintCheckedChange,
      handlePrintStockOutOrder,
      expressNoRef,
      handlePrintDeliver,
      handlePrintBox,
    } = this.props.store;

    return (
      <div className={styles.left}>
        <div className={styles.bigCard}>
          <div className={styles.header}>
            <div className={styles.tag}>
              补打快递
            </div>
          </div>
          <div className={styles.content}>
            <div className={styles.expressBox}>
              <span className={styles.leftLine}/>
              <Select
                allowClear
                bordered={false}
                className={styles.selectExpress}
                onChange={handleExpressChange}
                options={expressList}
                placeholder="请选择快递"
                value={selectExpress}
              />
            </div>

            <div className={styles.label}>
              请扫描快递单号或唯一码或箱单号
            </div>
            <Input
              autoComplete="off"
              autoFocus
              className={styles.input}
              onChange={handleExpressNoChange}
              onPressEnter={queryTrackingNumber}
              placeholder="请扫描快递单号或唯一码或箱单号"
              ref={expressNoRef}
              value={expressNo}
            />
            <div className={styles.choice}>
              <Tooltip
                color="#fff"
                overlayClassName={styles.reprintExpressTipText}
                title="打印一次将打印波次内订单的快递单数量"
              >
                <InfoCircleOutlined className={styles.tipIcon}/>
              </Tooltip>
              <Checkbox
                checked={printChecked}
                onChange={handlePrintCheckedChange}
              >
                扫描成功后直接打印
              </Checkbox>
            </div>
            <div className={styles.tipOthers}>
              注: 未选择转发快递自动打印原快递, 选择转发打印新快递
            </div>
            <Space
              className={styles.opeartions}
              size={4}
            >
              <Permission permissionId="2694_716">
                <Button
                  onClick={handlePrintCheck.bind(this, 'UPDATE')}
                  type="primary"
                >
                  打印新快递面单
                </Button>
              </Permission>
              <Permission permissionId="2694_715">
                <Button
                  onClick={handlePrintCheck.bind(this, 'DEFALUT')}
                  type="primary"
                >
                  打印原快递面单
                </Button>
              </Permission>
              <Permission permissionId="2694_717">
                <Button
                  onClick={handlePrintStockOutOrder}
                  type="primary"
                >
                  打印出库面单
                </Button>
              </Permission>
              <Button
                onClick={handlePrintDeliver}
                type="primary"
              >
                打印发货单
              </Button>
              <Button
                onClick={handlePrintBox}
                type="primary"
              >
                打印箱单
              </Button>
            </Space>
          </div>
        </div>
      </div>
    );
  }
}

@inject('store')
@observer
class Right extends React.Component<{ store?: Store; }> {
  render() {
    const {
      operationResult,
    } = this.props.store;

    return (
      <div className={styles.right}>
        <div className={styles.bigCard}>
          <div className={styles.header}>
            <div className={styles.title}>
              打印结果
            </div>
          </div>
          {operationResult.wmsOrderId && (
            <div className={styles.content}>
              <div className={styles.result}>
                <div className={styles.status}>
                  <CheckCircleOutlined className={styles.statusIcon}/>
                  <div className={styles.statusText}>
                    打印成功
                  </div>
                </div>
                {
                  (() => {
                    if (operationResult.currentCourierNo) {
                      return (
                        <Space size={40}>
                          <StatusCard
                            code={operationResult.previousCourierNo}
                            express={operationResult.previousCourierName}
                            title="原快递"
                          />
                          <StatusCard
                            code={operationResult.currentCourierNo}
                            express={operationResult.currentCourierName}
                            title="转发快递"
                          />
                        </Space>
                      );
                    } else {
                      return (
                        <Space size={40}>
                          <StatusCard
                            code={operationResult.previousCourierNo}
                            express={operationResult.previousCourierName}
                            title="快递单号"
                          />
                        </Space>
                      );
                    }
                  })()
                }
              </div>
              <div className={styles.title}>
                操作记录
              </div>
              <div className={styles.opeartionsBox}>
                <div className={styles.opeartionsContent}>
                  <Timeline>
                    {operationResult && [operationResult].map((item) => (
                      <Timeline.Item key={item.wmsOrderId}>
                        <>
                          <div className={styles.itemLine}>
                            <span>
                              平台编号:
                              <span className={styles.value}>
                                {item.platformNo}

                              </span>
                            </span>
                            <span className={`${styles.operationStatus} ${item.submitStatus ? styles.statusSuccess : styles.statusFailed}`}>
                              {item.submitStr}
                            </span>
                            <span className={styles.operationTime}>
                              {/* 2012-12-12 12:12:00 */}
                            </span>
                          </div>
                          <div className={styles.itemLine}>
                            <div>
                              {item.currentCourierNo && (
                                <span
                                  className={styles.currentExpress}
                                >
                                  转发快递:
                                  <span className={styles.value}>

                                    {item.currentCourierNo}
                                    (
                                    {item.currentCourierName}
                                    )
                                    <CopyToClipboard
                                      onCopy={() => message.success({
                                        content: '复制成功',
                                        key: item.currentCourierNo || '',
                                      })}
                                      text={item.currentCourierNo || ''}
                                    >
                                      <span
                                        className="icon icon-function"
                                      />
                                    </CopyToClipboard>
                                  </span>
                                </span>
                              )}
                              <span>
                                {item.currentCourierNo ? '原快递' : '快递单号'}
                                :
                                <span className={styles.value}>
                                  {item.previousCourierNo}
                                  (
                                  {item.previousCourierName}
                                  )
                                  { !item.currentCourierName && (
                                    <CopyToClipboard
                                      onCopy={() => message.success({
                                        content: '复制成功',
                                        key: item.previousCourierNo || '',
                                      })}
                                      text={item.previousCourierNo || ''}
                                    >
                                      <span className="icon icon-function"/>
                                    </CopyToClipboard>
                                  )}
                                </span>
                              </span>
                              <input
                                id="copyText"
                                style={{ opacity: 0 }}
                              />
                            </div>
                          </div>
                        </>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

function StatusCard(props: { title: string; code: string; express: string; type?: string; }) {
  const {
    title,
    code,
    express,
    type,
  } = props;
  return (
    <div className={styles.cardBox}>
      <div
        className={styles.card}
        style={{ background: type ? 'linear-gradient(135deg, #9B9FAD 0%, #A9ABB6 100%)' : 'linear-gradient(135deg, #4B6CFF 0%, #1578FF 100%)' }}
      >
        <div className={styles.text}>
          <div className={styles.cardTitle}>
            {title}
          </div>
          <div className={styles.code}>
            {code}
          </div>
        </div>
        <div className={styles.express}>
          {express}
        </div>
      </div>
    </div>
  );
}

export default function() {
  return (
    <Provider store={store}>
      <div className={styles.reprintExpress}>
        <Left/>
        <Right/>
      </div>
    </Provider>
  );
}
